import { render, staticRenderFns } from "./shouYing.vue?vue&type=template&id=921ccbc6&scoped=true&"
import script from "./shouYing.vue?vue&type=script&lang=js&"
export * from "./shouYing.vue?vue&type=script&lang=js&"
import style0 from "@/styles/pc/home.less?vue&type=style&index=0&id=921ccbc6&lang=less&scoped=true&"
import style1 from "@/styles/pc/homewe.less?vue&type=style&index=1&id=921ccbc6&lang=less&scoped=true&"
import style2 from "@/styles/phone/shouhome.less?vue&type=style&index=2&id=921ccbc6&lang=less&scoped=true&"
import style3 from "@/styles/phone/shouAbout.less?vue&type=style&index=3&id=921ccbc6&lang=less&scoped=true&"
import style4 from "@/styles/phone/shouContact.less?vue&type=style&index=4&id=921ccbc6&lang=less&scoped=true&"
import style5 from "@/styles/phone/shouChan.less?vue&type=style&index=5&id=921ccbc6&lang=less&scoped=true&"
import style6 from "./shouYing.vue?vue&type=style&index=6&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "921ccbc6",
  null
  
)

export default component.exports